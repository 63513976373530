import React from "react";
import javascript from "../assets/javascript.png";
import node from "../assets/node.png";
import react from "../assets/react.png";
import github from "../assets/github.png";
import python from "../assets/python.png";
import typescript from "../assets/typescript.png";
import firebase from "../assets/firebase.png";
import psql from "../assets/psql.png";

const Skills = () => {
  const skills = [
    {
      id: 1,
      skill: "Javascript",
      image: javascript,
      style: "shadow-yellow-500",
    },
    {
      id: 2,
      skill: "Node JS",
      image: node,
      style: "shadow-green-500",
    },
    {
      id: 3,
      skill: "React",
      image: react,
      style: "shadow-blue-300",
    },
    {
      id: 4,
      skill: "Python",
      image: python,
      style: "shadow-yellow-300",
    },
    { id: 5, skill: "TypeScript", image: typescript, style: "shadow-blue-500" },
    {
      id: 6,
      skill: "GitHub",
      image: github,
      style: "shadow-white",
    },
    {
      id: 8,
      skill: "Firebase",
      image: firebase,
      style: "shadow-yellow-500",
    },
    {
      id: 7,
      skill: "PostGreSQL",
      image: psql,
      style: "shadow-blue-700",
    },
  ];

  return (
    <section
      name="skills"
      className="bg-gradient-to-b from-blue-800 to-black w-full h-screen"
    >
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
        <div>
          <h2 className="text-4xl font-bold p-2 inline">My Skills</h2>
        </div>
        <p className="py-6">
          I've have worked with the following technologies:
        </p>
        <div className="w-full grid grid-cols-2 ss:grid-cols-3 sm:grid-cols-4 gap-8 text-center py-8 px-12 sm:px-0">
          {skills.map(({ skill, image, id, style }) => {
            return (
              <div
                key={id}
                className={"shadow hover:shadow-lg py-2 rounded-lg " + style}
              >
                <img src={image} alt="" className="w-20 mx-auto" />
                <p className="mt-4">{skill}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Skills;
