// import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

function NavBar(props) {
  // const [nav, setNav] = useState(false);
  const nav = props.navStatus;
  const setNav = props.setNavStatus;
  const links = [
    {
      id: 1,
      text: "home",
      link: "/",
    },
    {
      id: 2,
      link: "about",
    },
    {
      id: 3,
      link: "projects",
    },
    {
      id: 4,
      link: "skills",
    },
    {
      id: 5,
      text: "get in touch",
      link: "contact",
    },
  ];

  const toggleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="flex justify-between items-center w-full h-20 px-4 text-white bg-black fixed">
      <div>
        <span className="text-4xl font-logo">
          <Link to="/">LDP</Link>
        </span>
      </div>
      <ul className="hidden md:flex">
        {links.map(({ id, link, text }) => {
          return (
            <li
              key={id}
              className="px-2 cursor-pointer capitalize hover:scale-105 duration-100"
            >
              <Link to={link}>{text || link}</Link>
            </li>
          );
        })}
      </ul>

      <div
        onClick={() => setNav(!nav)}
        className="cursor-pointer z-10 md:hidden"
      >
        {nav ? <FaTimes size={25} /> : <FaBars size={25} />}
      </div>

      {nav ? (
        <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-blue-700">
          {links.map(({ id, link, text }) => {
            return (
              <li
                key={id}
                className="px-4 py-4 text-2xl cursor-pointer capitalize hover:scale-105 duration-100"
              >
                <Link to={link} onClick={toggleNav}>
                  {text || link}
                </Link>
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
}

export default NavBar;
