import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";

export const SocialLinks = () => {
  const links = [
    {
      id: 1,
      icon: <FaGithub />,
      href: "https://github.com/lewispricey",
    },
    {
      id: 2,
      icon: <FaLinkedin />,
      href: "https://www.linkedin.com/in/lewis-price/",
    },
    {
      id: 3,
      icon: <HiOutlineMail />,
      href: "mailto:lewis@lewisprice.co.uk",
    },
    {
      id: 1,
      icon: <BsFillPersonLinesFill />,
      href: "/cv.pdf",
    },
  ];

  return (
    <div>
      <ul className="flex items-center text-white text-2xl">
        {links.map((link) => {
          return (
            <li id={link.id} className="pr-6  hover:text-gray-300">
              <a href={link.href}>{link.icon}</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
