import React from "react";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import WorkingPic from "../assets/working2.jpg";
import { SocialLinks } from "./SocialLinks";
const Home = (props) => {
  const nav = props.navStatus;
  return (
    <div
      name="home"
      className="h-screen w-full bg-gradient-to-b from-black to-blue-800"
    >
      <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
        <header className="flex flex-col justify-center h-full">
          <h1 className=" text-4xl md:text-6xl font-bold text-white">
            Hey I'm Lewis
          </h1>
          {nav ? (
            <p>I'm a software engineer...</p>
          ) : (
            <p className="text-2xl py-4 text-gray-200 max-w-md">
              <Typewriter
                options={{
                  strings: [
                    "I'm a software engineer...",
                    "I'm a master Googler...",
                    "I'm a full stack developer...",
                    "I'm a text file editor...",
                    // "I'm a student mentor...",
                    "I'm a coffee connoisseur...",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </p>
          )}
          <SocialLinks />
          <button className="text-blue-800 font-bold w-fit px-7 py-3 mt-5 my-2 flex items-center rounded-3xl bg-white hover:bg-gray-300">
            <Link to="/projects">Portfolio</Link>
          </button>
        </header>

        <div className="hidden md:flex">
          <img
            src={WorkingPic}
            alt="Lewis Price writing on a notepad sat in a computer chair at a grey desk"
            className="rounded-3xl mx-auto mb-10 w-2/3 md:w-2/3"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
