import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className="w-full h-screen bg-gradient-to-b from-blue-800 to-black text-white"
    >
      <section className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <h3 className="text-4xl font-bold inline">About Me</h3>
        </div>
        <p className="mt-20 text-xl">
          I'm an excellent problem solver and aspiring junior developer,
          currently located in the southwest UK but open to relocating. Driven
          by my love of learning, I thrive on complex problems being able to
          independently find solutions as well as working collaboratively and
          creatively, to find the best solution.
        </p>
        <p className="mt-10 text-xl">
          To pursue a career change to software development, I recently
          completed a coding bootcamp with Northcoders. A 12 week industry lead
          coding bootcamp, focused on full stack software development. During
          the bootcamp I learned industry best practices such as pair
          programming, version control and test driven development. The final 2
          weeks of the bootcamp had me working as part of a small agile team to
          brainstorm and deliver a project using new technologies. As a team we
          utilised daily standups, code reviews & tracked progress using Kanban
          in order to stay on track to meet our deadline.
        </p>

        <p className="mt-10 text-xl">
          Over the next 5 years I’m committed to continue improving my skill
          set, with the aim of becoming a senior developer within that time.
        </p>
      </section>
    </div>
  );
};

export default About;
