import React, { useState } from "react";

export const Contact = () => {
  const { name, setName } = useState();
  const { email, setEmail } = useState();
  const { message, setMessage } = useState();

  return (
    <div
      name="contact"
      className="w-full h-screen bg-gradient-to-b from-black to-blue-800 p-4 text-white"
    >
      <div className="flex flex-col p-4 justify-center max-w-screen-lg mx-auto h-full">
        <div>
          <h2 className="text-4xl font-bold inline ">Contact Me</h2>
        </div>
        <p className="py-6">Submit the form below to get in touch:</p>
        <form action="" className="flex flex-col w-3/4 mx-auto md:w-1/2">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            name="name"
            id="name"
            className="my-4 p-2 bg-transparent border-2 rounded-md text-white"
          />
          <label htmlFor="email">Email Address:</label>
          <input
            type="text"
            name="email"
            id="email"
            className="my-4 p-2 bg-transparent border-2 rounded-md text-white"
          />
          <label htmlFor="message">Message:</label>
          <textarea
            name="message"
            id="message"
            rows="10"
            className=" my-4 p-2 bg-transparent border-2 rounded-md text-white"
          ></textarea>
          <button className="p-2 bg-white border-2 rounded-xl text-blue-800 px-8 py-3 my-4 mx-auto flex font-bold items-center">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
