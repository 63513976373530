import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState } from "react";
import About from "./components/About";
import { Contact } from "./components/Contact";
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import Projects from "./components/Projects";
import Skills from "./components/Skills";

function App() {
  const [nav, setNav] = useState(false);
  return (
    <BrowserRouter>
      <div>
        <NavBar navStatus={nav} setNavStatus={setNav} />
        <Routes>
          <Route
            path="/"
            element={<Home navStatus={nav} setNavStatus={setNav} />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
