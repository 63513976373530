import React from "react";
import beLewsNews from "../assets/portfolio/be-lewsnews.png";
import feLewsNews from "../assets/portfolio/fe-lewsnews.png";
import swapBook from "../assets/portfolio/swapbook.png";

const Portfolio = () => {
  const projects = [
    {
      id: 1,
      img: beLewsNews,
      stack: [
        {
          name: "Javascript",
          icon: "",
        },
      ],
      repo: "https://github.com/lewispricey/be-lews-news",
      live: "https://lews-news.herokuapp.com/api",
    },
    {
      id: 2,
      img: feLewsNews,
      stack: [
        {
          name: "Javascript",
          icon: "",
        },
      ],
      repo: "https://github.com/lewispricey/fe-lews-news",
      live: "https://lewsnews.lewisprice.co.uk",
    },
    {
      id: 1,
      img: swapBook,
      stack: [
        {
          name: "Typescript",
          icon: "",
        },
      ],
      repo: "https://github.com/orgs/Team-Top-Hat/repositories",
      live: "https://northcoders.com/projects/sep-2022/swapbook",
    },
  ];

  return (
    <section
      name="projects"
      className="bg-gradient-to-b from-black to-blue-800 w-full text-white md:h-screen"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <h2 className="text-4xl font-bold inline">Projects</h2>
          <p className="py-6">Check out some of my recent projects:</p>
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0">
          {projects.map((project) => {
            return (
              <div
                key={project.id}
                className="shadow-md shadow-gray-800 rounded-lg"
              >
                <img className="rounded-t-md" src={project.img} alt="" />
                <div className="flex items-center justify-center">
                  Stack Icons
                  <img src="" alt="" />
                </div>
                <div className="flex items-center justify-center">
                  <a
                    href={project.repo}
                    className="w-1/2 px-6 py-3 m-2 text-center"
                  >
                    <button>Github</button>
                  </a>
                  <a
                    href={project.live}
                    className="w-1/2 px-6 py-3 m-2 text-center"
                  >
                    <button>Live</button>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
